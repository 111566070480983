import React, { useState } from 'react';
import { Routes, Route, BrowserRouter, useLocation, useParams } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Flex,
  Grid,
  Button,
  Heading,
  ButtonGroup,
  Center,
  extendTheme,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react';
import { QuizPage } from './QuizPage';
import { NavBar } from './NavBar';
import { Header } from './Header';
import { Card } from './Card';
import { Footer } from './Footer';
import { FadeInFooter } from './FadeInFooter';
import { Article } from './Article';
import { Home } from './Home';
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';
import { Results } from './Results';
import { Payment } from './Payment';
import { Purchase } from './Purchase';
import { IsMobile} from './IsMobile';
import { ScrollToTop } from './ScrollToTop';
import { CalculationPage } from './Calculation';
import { StartTest } from './StartTest';
import { theme } from './theme'
import { ParticleBackground } from './ParticleBackground';

const pPages = ['/', '/starttest', '/', '/article/education', '/article/defense', '/article/hiring', 'press', 'contanct'];

function App() {
  const [page, setPage] = useState('article');
  let pageContent;

  return (
      <BrowserRouter>
        <ScrollToTop />
        <ChakraProvider theme={theme}>
            <NavBar setPage={setPage} />

            <RouteManager />

            <Box mt={50}>
              <FadeInFooter />
              <Footer />
            </Box>
        </ChakraProvider>
      </BrowserRouter>
  );
}

function RouteManager() {
  let location = useLocation();

  return (
    <TransitionGroup>
      <div className={(pPages.includes(location.pathname) || location.pathname.includes('article')) ? "ParticlesContainer" : "ParticlesContainer hide"}>
        <ParticleBackground />
      </div>
      <CSSTransition
              key={location.pathname}
              timeout={300}
              classNames="page"
            >
              <Routes>
                <Route path="/" element={<div className="page"><Home /></div>} />
                <Route path="/article" element={<div className="page"><Article /></div>} />
                <Route path="/starttest" element={<div className="page"><StartTest /></div>} />
                <Route path="/test" element={<div className="page"><QuizPage /></div>} />
                <Route path="/results/basic" element={<div className="page"><Results assessment="basic" /></div>} />
                <Route path="/results/certificate" element={<div className="page"><Results assessment="certificate" /></div>} />
                <Route path="/results/full" element={<div className="page"><Results assessment="full" /></div>} />
                <Route path="/results" element={<div className="page"><Results /></div>} />
                <Route path="/calculation" element={<div className="page"><CalculationPage /></div>} />
                <Route path="/payment" element={<div className="page"><Payment /></div>} />
                <Route path="/purchase/basic" element={<div className="page"><Purchase assessment="basic" /></div>} />
                <Route path="/purchase/certificate" element={<div className="page"><Purchase assessment="certificate" /></div>} />
                <Route path="/purchase/full" element={<div className="page"><Purchase assessment="full" /></div>} />
                <Route path="/privacy" element={<div className="page"><Article articleChoice="privacyPolicy" /></div>} />
                <Route path="/article/education" element={<div className="page"><Article articleChoice="education" /></div>} />
                <Route path="/article/defense" element={<div className="page"><Article articleChoice="defense" /></div>} />
                <Route path="/article/hiring" element={<div className="page"><Article articleChoice="hiring" /></div>} />
                <Route path="/article/g_factor" element={<div className="page"><Article articleChoice="g_factor" /></div>} />
                <Route path="/article/IQ_tests_and_IQ" element={<div className="page"><Article articleChoice="IQTestsAndIQ" /></div>} />
                <Route path="/press" element={<div className="page"><Article articleChoice="press" /></div>} />
                <Route path="/contact" element={<div className="page"><Article articleChoice="contact" /></div>} />
              </Routes>
      </CSSTransition>
    </TransitionGroup>

  );
}
export default App;
