import { ReactNode } from 'react';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Center
} from '@chakra-ui/react';
import { Checkout } from './Checkout';

export function Purchase(props) {
  const assessment = props.assessment;
  const plans = {
    basic:{name:'Basic Assessment', price:"10"},
    certificate:{name:'Assessment and Certificate', price:"15"},
    full:{name:'Full Assessment', price:"20"}
  }
  const plan = plans[assessment];

  return (
    <Box mt={20}>
      <Center>
        <Heading fontSize="28px">{plan['name']} ${plan['price']}</Heading>
      </Center>
      <Box mt={10}>
        <Checkout assessment={assessment} />
      </Box>
    </Box>
  )
}
