import React from 'react';
import {
  Box,
  Heading,
  Link,
  chakra,
  Center,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Button,
  Flex,
} from '@chakra-ui/react';

export function Jobs() {
  return (
    <div>
    <chakra.p fontWeight={'bold'} fontSize={33}>
      Job Applications
    </chakra.p>
    <chakra.p
      lineHeight={1}
      fontWeight={'medium'}
      color={'gray.500'} fontSize={22}
      lineHeight={1.3}
      mt={2}
      pb={5}>
      Predict real-world job performance using our scientifically validated tests
    </chakra.p>

    <chakra.p
      lineHeight={1.4}
      letterSpacing={'.1px'}
      fontSize={'20px'}>
      Our screening tests identify the best candidates and make your hiring decisions faster, easier, and bias-free. Our unique personality and value tests allow you to get to know your applicants as real people – not just pieces of paper.
      <br /><br />
      We offer top of the line ease of use. Within our platform, easily create testing links and paste them directly into your job postings. Applicants take tests remotely with a click of a button, and results are instant. Aptilink's testing platform integrates with a wide range of applicant tracking systems through our API.
      <br /><br />
      Administer unlimited tests with flat-fee subscription pricing, resulting in predictable costs and more efficient hiring.
      <br /><br />
      Our tests are administered online and reports are generated within a day. Reach out to us below for a quote.
    </chakra.p>
    <a href="mailto:info@Aptilink.io">
    <Button mt={8} width={200}>Inquire</Button>
    </a>
    </div>
  )
}
