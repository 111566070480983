import React from 'react';
import { Flex, Center, Box, useColorModeValue, Header } from '@chakra-ui/react';
import { Timer } from './Timer';
import { IsMobile } from './IsMobile';

export function QuizInfo(props) {
  const questionNum = props.questionNum;
  if(questionNum > 40) {
    questionNum = 40;
  }

  return (
    <Center>
    <Box mb={7} width={IsMobile() ? "95%" : "100%"}>
      <Box width="100%" height="10px" className="questionBar" backgroundColor={useColorModeValue('gray.200', 'gray.700')} mb={1}>
        <Box width={(questionNum)/40*100+"%"} height="10px" backgroundColor="#0392cf" opacity="0.9">
        </Box>
      </Box>

      <Box float="left">
        <b><p>{questionNum}/40</p></b>
      </Box>
      <Box float="right">
        <Timer />
      </Box>
    </Box>
    </Center>
  );
}
