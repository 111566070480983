import { React } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

export function FadeInFooter() {
  const useless = "${useColorModeValue('gray.50', 'gray.900')}";
  let background;
  if(useColorModeValue('1', '2') == '2') {
    background='linear-gradient(to bottom, rgba(23,25,35,0), rgba(23,25,35,1))';
  } else {
      background='linear-gradient(to bottom, rgba(247,250,252,0), rgba(247,250,252,1))';
  }
  return (
    <div style={{
      backgroundImage: background
    }} className="FadeInFooter"></div>
  )
}
