import React from 'react';
import {
  Box,
  Text,
  Link,
  Flex,
  Grid,
  Button,
  Heading,
  Center
} from '@chakra-ui/react';
import { Header } from './Header';
import { ParticleBackground } from './ParticleBackground';
import { Card } from './Card';
import MetaTags from 'react-meta-tags';

export function Home() {
  return (
    <Box>
      <MetaTags>
        <meta id="meta-description" name="description" content="Join over a million of our users who've discovered their IQ score. Take our test for a certified IQ assessment." />
      </MetaTags>
      <Header />
      <Box mt={100}>
        <Card />
      </Box>
    </Box>
  );
}
