import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Box, useColorModeValue, chakra, ButtonGroup, Flex, Heading, Center } from '@chakra-ui/react';
import {IsMobile} from './IsMobile';
import { ParticleBackground } from './ParticleBackground';

const backgrounds = [
  `url("data:image/svg+xml, %3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'560\' height=\'185\' viewBox=\'0 0 560 185\' fill=\'none\'%3E%3Cellipse cx=\'102.633\' cy=\'61.0737\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23ED64A6\' /%3E%3Cellipse cx=\'399.573\' cy=\'123.926\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23F56565\' /%3E%3Cellipse cx=\'366.192\' cy=\'73.2292\' rx=\'193.808\' ry=\'73.2292\' fill=\'%2338B2AC\' /%3E%3Cellipse cx=\'222.705\' cy=\'110.585\' rx=\'193.808\' ry=\'73.2292\' fill=\'%23ED8936\' /%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ED8936'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%2348BB78'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%230BC5EA'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%23ED64A6'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23ED8936'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%2348BB78'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%230BC5EA'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23ED64A6'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ECC94B'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%239F7AEA'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%234299E1'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%2348BB78'/%3E%3C/svg%3E")`,
];
const index = 2;

export function StartTest(props) {

  return (
    <Center>
    <Box mt="-30px" boxShadow="lg" rounded={'xl'} p={IsMobile() ? 4 : 8} pt={8} pb={8}
      justifyContent={'space-between'}
      position={'relative'}
      width="100%"
      maxW="650px"
      bg={useColorModeValue('white', 'gray.800')}
      _before={{
        content: '""',
        position: 'absolute',
        zIndex: '-1',
        height: 'full',
        maxW: '640px',
        width: 'full',
        filter: 'blur(40px)',
        transform: 'scale(0.98)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        top: 0,
        left: 0,
        backgroundImage: backgrounds[index % 4],
      }}>
      <Center mt={5}>
        <Heading fontSize="30px" fontWeight="500">
          Test Instructions
        </Heading>
      </Center>
      <chakra.p
        lineHeight={1}
        fontWeight={'medium'}
        color={'gray.500'} fontSize={22}
        lineHeight={1.3}
        mt={2}
        pb={1} align="center">
        Read each of the following instructions carefully
      </chakra.p>
      <Box fontSize="20px" lineHeight={1.4} letterSpacing='.1px' align="left" p={2} mt="10px" fontWeight="300">
        <Box>
          <p>
            <span>&#8226;</span> You will have 40 minutes to answer the 40 questions.
          </p>
        </Box>
        <Box mt="5px">
          <p>
            <span>&#8226;</span> For each question choose the appropriate shape to replace the shape that is missing.
          </p>
        </Box>
        <Box mt="5px">
          <p>
            <span>&#8226;</span> You can skip questions and return back to them later.
          </p>
        </Box>
        <Box mt="5px">
          <p>
            &#8226; Take your time. We weigh number of correct answers significantly more than completion time.
          </p>
        </Box>
      </Box>
      <Center mt="25px" pb="10px">
        <Link to="/test">
          <Button size="lg">
            Start the Test
          </Button>
        </Link>
      </Center>
    </Box>
    </Center>
  );
}
