import React, { useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { Quiz } from './Quiz';

export function QuizPage() {
  const [questionNum, setQuestionNum] = useState('1');
  const [answers, setAnswers] = useState('quiz');

  if(questionNum > 40) {
    window.location.href = '/calculation';
  }

  return (
    <Quiz questionNum={questionNum} setQuestionNum={setQuestionNum} />
  );
}
