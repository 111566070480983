import React from 'react';
import {
  Box,
  Heading,
  Link,
  chakra,
  Center,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Button,
  Flex,
} from '@chakra-ui/react';

export function Education() {
  return (
    <div>
    <chakra.p fontWeight={'bold'} fontSize={33}>
      Our School Assessments
    </chakra.p>
    <chakra.p
      lineHeight={1}
      fontWeight={'medium'}
      color={'gray.500'} fontSize={22}
      lineHeight={1.3}
      mt={2}
      pb={5}>
      Equal opportunity testing with an emphasis on growth learning
    </chakra.p>

    <chakra.p
      lineHeight={1.4}
      letterSpacing={'.1px'}
      fontSize={'20px'}>
      Traditional intelligence testing does far more harm than good to children. Children often have a fixed mindset to learning underestimating the plasticity of their developing brains and take concrete scores like IQ to heart. In order to handle these concerns we've developed a less abrasive model.
      <br /><br />
      We compile a personalized report for each student on what subjects they're falling behind their potential in. Our screening identifies students that may need extra help and students that should be placed in gifted programmes.
      <br /><br />
      Our tests are administered online and reports are generated within a day. We can design the reports in your school's branding. Reach out to us below for a quote.
    </chakra.p>
    <a href="mailto:info@Aptilink.io">
    <Button mt={8} width={200}>Inquire</Button>
    </a>
    </div>
  )
}
