import React, {useEffect, useState} from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuIcon,
  MenuCommand,
  MenuDivider,
  Box,
  Button,
  Heading,
  ButtonGroup,
  Stack,
  useColorModeValue
} from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Link, useLocation } from 'react-router-dom';
import { IsMobile } from './IsMobile';
import { IsBigQuiz } from './IsBigQuiz';

function MenuToggle(props) {
  const {setIsOpen, isOpen} = props;

  return (
    <Button variant="ghost" onClick={() => setIsOpen(!isOpen)}>
      {!isOpen && <HamburgerIcon />}
      {isOpen && <CloseIcon />}
    </Button>
  )
}

const pPages = ['/', '/starttest', '/', '/article/education', '/article/defense', '/article/hiring', 'press', 'contanct'];

export function NavBar(props) {
  const setPage = props.setPage;
  const [isOpen, setIsOpen] = React.useState(false);

  const location = useLocation();

  var navBg = useColorModeValue('white', 'rgb(26, 32, 44)');
  var ismobile = IsMobile();
  var isbigquiz = IsBigQuiz();

  return (
    <Box>
      {(location.pathname != '/test' || !isbigquiz) &&
      <Box  mb={20} pb={ismobile ? 10-(isOpen*7) : 70} bg={ismobile ? navBg : ""} className={(ismobile && (pPages.includes(location.pathname) || location.pathname.includes('article'))) ? "mobilenav" : "nav"} >
        <Box p={5} position={ismobile ? "default" : "absolute"} width="100%">
          <Link to="/">
            <Heading float="left" fontSize="25px" className="NavLogo" >
              Aptilink
            </Heading>
          </Link>
          <ButtonGroup float="right">
          {!ismobile && (
          <div>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
              Services
            </MenuButton>
            <MenuList>
            <Link to="/article/education" onClick={() => setIsOpen(false)}><MenuItem>Education</MenuItem></Link>
            <Link to="/article/defense" onClick={() => setIsOpen(false)}><MenuItem>Defense Forces</MenuItem></Link>
            <Link to="/article/hiring" onClick={() => setIsOpen(false)}><MenuItem>Jobs</MenuItem></Link>
            <Link to="/starttest" onClick={() => setIsOpen(false)}><MenuItem>Personal Test</MenuItem></Link>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
              Learn More
            </MenuButton>
            <MenuList>
              <Link to="/article/IQ_tests_and_IQ" onClick={() => setIsOpen(false)}><MenuItem>What is IQ?</MenuItem></Link>
              <Link to="/article/g_factor" onClick={() => setIsOpen(false)}><MenuItem>The G Factor</MenuItem></Link>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
              Contact
            </MenuButton>
            <MenuList>
              <Link to="/contact" onClick={() => setIsOpen(false)}><MenuItem>Contact Us</MenuItem></Link>
              <Link to="/press" onClick={() => setIsOpen(false)}><MenuItem>Press</MenuItem></Link>
            </MenuList>
          </Menu>
            </div>
            )}
            <ColorModeSwitcher />
            {ismobile && <MenuToggle isOpen={isOpen} setIsOpen={setIsOpen} />}
          </ButtonGroup>

          {(ismobile && isOpen) &&
            <Stack mt={50}>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
                Services
              </MenuButton>
              <MenuList>
              <Link to="/article/education" onClick={() => setIsOpen(false)}><MenuItem>Education</MenuItem></Link>
              <Link to="/article/defense" onClick={() => setIsOpen(false)}><MenuItem>Defense Forces</MenuItem></Link>
              <Link to="/article/hiring" onClick={() => setIsOpen(false)}><MenuItem>Jobs</MenuItem></Link>
              <Link to="/starttest" onClick={() => setIsOpen(false)}><MenuItem>Personal Test</MenuItem></Link>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
                Learn More
              </MenuButton>
              <MenuList>
                <Link to="/article/IQ_tests_and_IQ" onClick={() => setIsOpen(false)}><MenuItem>What is IQ?</MenuItem></Link>
                <Link to="/article/g_factor" onClick={() => setIsOpen(false)}><MenuItem>The G Factor</MenuItem></Link>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
                Contact
              </MenuButton>
              <MenuList>
                <Link to="/contact" onClick={() => setIsOpen(false)}><MenuItem>Contact Us</MenuItem></Link>
                <Link to="/press" onClick={() => setIsOpen(false)}><MenuItem>Press</MenuItem></Link>
              </MenuList>
            </Menu>
            </Stack>
          }
        </Box>
      </Box>}
    </Box>
  );
}
