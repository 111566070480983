import { read_cookie } from 'sfcookies';

export function getResults(setResponse) {
  const answers = JSON.parse(read_cookie('answers'));
  var ans_string = "";
  for(var i=0; i<40; ++i) {
    ans_string += answers[i];
  };
  var session_id = document.location.search.split('session_id=')[1];
  console.log(ans_string);
  fetch('https://tws-audio.com/iq/scorer.php?answers='+ans_string+'&session_id='+session_id)
        .then(response => response.text())
        .then(text => {
          setResponse(text)
        });
}
