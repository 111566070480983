import {useState, React} from 'react';
import { Button, Box, ButtonGroup, Flex, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

function handleClickPrev(setQuestionNum) {
  setQuestionNum((prev) => {
    if(prev > 1) {
      return (parseInt(prev)-1).toString();
    }
    return prev;
  });
}
function handleClickNext(setQuestionNum) {
  setQuestionNum((prev) => {
    if(prev <= 40) {
      return (parseInt(prev)+1).toString();
    }
    return prev;
  });
}

export function Navigation(props) {
  const questionNum = props.questionNum;
  const setQuestionNum = props.setQuestionNum;
  const [isOpen, setIsOpen] = useState(false);


  return (
      <Flex justifyContent="center">
        <ButtonGroup spacing="3" size="md" variant="outline">
          { questionNum > 1 && <Button leftIcon={<ArrowBackIcon />} onClick={() => handleClickPrev(setQuestionNum)} >Previous</Button> }
          { questionNum < 40 && <Button width="110px" rightIcon={<ArrowForwardIcon />} onClick={() => handleClickNext(setQuestionNum)} >Next</Button> }
          { questionNum >= 40 && <Button width="110px" rightIcon={<ArrowForwardIcon />} onClick={() => setIsOpen(true)} >Finish</Button> }
        </ButtonGroup>
        <AlertDialog isCentered={true} isOpen={isOpen}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Finish Test
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You won't be able to change your answers afterwords.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Link to="/calculation">
                <Button colorScheme="red" ml={3}>
                  Finish
                </Button>
              </Link>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </Flex>
  );
}
