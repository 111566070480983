import { ReactNode } from 'react';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { Checkout } from './Checkout';
import { Link } from 'react-router-dom';

import { read_cookie } from 'sfcookies';


function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'} width="300px">
      {children}
    </Box>
  );
}

export function Payment() {
  const answers = JSON.parse(read_cookie('answers'));
  var empty_count = 0;
  for(var i=0; i<41; ++i) {
    if(answers[i] == '-') {
      empty_count += 1;
    }
  };
  if(empty_count == 40) {
    window.location.href = '/starttest';
  }

  return (
    <Box mt={-5}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          IQ Test Complete
        </Heading>
        <Text fontSize="lg" color={'gray.500'} pl={1} pr={1}>
          Choose from one of our three assessment options. Results are instant.
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Basic
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                10
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List height="96px" spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                IQ assessment
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <a href="https://buy.stripe.com/7sIeYN8CreGT7kYaEF">
                <Button w="full" colorScheme="red" variant="outline">
                  Choose Plan
                </Button>
              </a>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={useColorModeValue('red.300', 'red.700')}
                px={3}
                py={1}
                color={useColorModeValue('gray.900', 'gray.300')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Most Popular
              </Text>
            </Box>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Complete
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  20
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}>
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                IQ Assessment
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Multiple Intelligences Report
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                IQ Certificate
              </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Analysis of cognitive strengths/weaknesses
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <a href="https://buy.stripe.com/3csdUJbOD2Yb5cQ6or">
                  <Button w="full" colorScheme="red">
                    Choose Plan
                  </Button>
                </a>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              IQ and Certificate
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                15
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={3} textAlign="start" px={12} height="96px">
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                IQ assessment
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                IQ Certificate
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <a href="https://buy.stripe.com/aEUg2R3i71U7axa3ce">
                <Button w="full" colorScheme="red" variant="outline">
                  Choose Plan
                </Button>
              </a>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}
