import React from 'react';
import {
  Box,
  Heading,
  Link,
  chakra,
  Center,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Button,
  Flex,
} from '@chakra-ui/react';

export function Press() {
  return (
    <div>
    <chakra.p fontWeight={'bold'} fontSize={33}>
      Press
    </chakra.p>

    <chakra.p
      lineHeight={1.4}
      letterSpacing={'.1px'}
      fontSize={'20px'} mt={5}>
      For press inquiries please reach out to us at <a href="mailto:info@aptilink.io" style={{textDecoration:"underline", color:"lightblue"}}>press@Aptilink.io</a>
    </chakra.p>
    </div>
  )
}
