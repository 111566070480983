import React, {useState, useEffect} from 'react';
import { Options } from './Options';
import { Flex, Center, Box } from '@chakra-ui/react';
import { IsMobile } from './IsMobile';
import { IsBigQuiz } from './IsBigQuiz'

export function Question(props) {
  const questionNum = props.questionNum;
  const setQuestionNum = props.setQuestionNum;

  return (
    <Box>
      {IsBigQuiz() &&
        <div>
          <Center mt={3}>
            <img src={"/images/questions/"+questionNum+"_0_0.png"} align="center" width="100%" />
          </Center>
          <Box mt={3}>
            <Options question_num={questionNum} setQuestionNum={setQuestionNum} />
          </Box>
        </div>
      }
      {!IsBigQuiz() &&
        <div>
          <Center mt={3}>
            <img src={"/images/questions/"+questionNum+"_0_0.png"} align="center" width="450px" />
            <Options question_num={questionNum} setQuestionNum={setQuestionNum} />
          </Center>
        </div>
      }
    </Box>
  );
}
