import React from 'react';
import { Center, chakra } from '@chakra-ui/react';

function secondsToMinutes(s){return(s-(s%=60))/60+(9<s?':':':0')+s}

export function Timer(props) {
  const [counter, setCounter] = React.useState(60*40);

  if(counter <= 0) {
      window.location.href = '/payment';
  }

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <p><b>{secondsToMinutes(counter)}</b></p>
  );

}
