import React, { useState, useEffect }  from 'react';
import { Flex, Box, Stack, Button, Center, Grid } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { bake_cookie, read_cookie } from 'sfcookies';
import { IsMobile} from './IsMobile';
import {IsBigQuiz} from './IsBigQuiz';

function getAnswerCookie(ind) {
  let answers = JSON.parse(read_cookie('answers'));
  return answers[ind];
}
function updateAnswerCookie(ind, answer) {
  let answers = JSON.parse(read_cookie('answers'));
  answers[ind] = answer;
  bake_cookie('answers', JSON.stringify(answers));
}

export function Options(props) {
  const question_num = props.question_num;
  const setQuestionNum = props.setQuestionNum;
  const [useless, setUseless] = useState(1);

  const n_options = 6;
  function OptionComponent() {
    const options = [];
    for(var option_num=1; option_num<=n_options; ++option_num) {
      const image_source = "/images/questions/"+question_num+"_"+option_num.toString()+"_0.png";
      options.push((
        <Button height={IsBigQuiz() ? "27vw" : "130px"} width="100%" isActive={option_num==getAnswerCookie(question_num-1)} onClick={handleClick} variant="outline" p={2}>
          <img className="option" src={image_source} id={option_num}/>
        </Button>
      ));
    }
    return options
  }

  function handleClick(e) {
    let option_num = e.target.id;
    updateAnswerCookie(question_num-1, option_num);
    setQuestionNum((prev) => {
      if(parseInt(prev) < 40) {
        return (parseInt(prev)+1).toString();
      } else {
        setUseless((prev) => {
          return prev+1;
        });
        return "40";
      }
    });
  }

  return (
    <Center>
      <Center width={IsBigQuiz() ? "100%" : ""}>
      <Grid templateColumns={IsBigQuiz() ? "repeat(3, 2fr)" : "repeat(2, 3fr)"} gap={3} width={IsBigQuiz() ? "85vw" : "300px"} mt={2}>
          {<OptionComponent />}
      </Grid>
      </Center>
    </Center>
  );
}
