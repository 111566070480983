import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  Tag,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Logo } from './Logo'

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export function Footer() {
  return (
    <Box className="footer"
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={'flex-start'}>
            <ListHeader>Product</ListHeader>
            Overview
            <Stack direction={'row'} align={'center'} spacing={2}>
              <Link to="article/education">Education</Link>
              <Tag
                size={'sm'}
                bg='rgb(238, 64, 53, .6)'
                ml={2}
                color={'white'}>
                New
              </Tag>
            </Stack>
            <Link className="footerlink" to="article/hiring">Hiring</Link>
            <Link className="footerlink"  to="article/defense">Defense</Link>
            <Link className="footerlink"  to="/starttest">Personal Test</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Learn More</ListHeader>
            <Link className="footerlink"  to="/article/IQ_tests_and_IQ">What is IQ?</Link>
            <Link className="footerlink"  to="/article/g_factor">The G Factor</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Contact</ListHeader>
            <Link className="footerlink"  to="/contact">Contact Us</Link>
            <Link className="footerlink"  to="/contact">Press</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Legal</ListHeader>
            <Link className="footerlink"  to="/privacy">Privacy Policy</Link>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box py={10}>
        <Flex
          align={'center'}
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 8,
          }}>
          <Logo />
        </Flex>
        <Text pt={6} fontSize={'sm'} textAlign={'center'}>
          © 2021 Aptilink. All rights reserved
        </Text>
      </Box>
    </Box>
  );
}
