import {
  Avatar,
  Box,
  chakra,
  Container,
  Flex,
  Icon,
  SimpleGrid,
  Center,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IsMobile } from "./IsMobile";
import { Link } from 'react-router-dom';

library.add(fas);

const testimonials = [
  {
    name: 'Education',
    role: 'Equal opportunity testing with an emphasis on growth learning',
    content:
      "We offer a wide range of aptitude tests to identify a student's strengths and weaknesses. We ensure each one of your students is performing to their true potential and identify any supports they may require.",
    color: '#ee4035',
    icon: 'chalkboard-teacher',
    call: 'Learn More',
    url: '/article/education'
  },
  {
    name: 'Defense Forces',
    role: 'Comprehensive aptitude and psychometric analysis for military and police use',
    content:
      "There is a wide variety of positions and job profiles in defense forces each demanding a certain set of skills. Our aptitude tests empower recuriters to make informed decisions with regard to matching requisites to abilities and personalities.",
    avatar:
      'https://images.unsplash.com/photo-1598550874175-4d0ef436c909?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
    color: '#f37736',
    icon: 'shield-alt',
    call: 'Learn More',
    url: '/article/defense'
  },
  {
    name: 'Job Applications',
    role: 'Predict real-world job performance using our scientifically validated tests',
    content:
      "Our screening tests identify the best candidates and make your hiring decisions faster, easier, and bias-free. Our unique personality and value tests allow you to get to know your applicants as real people – not just pieces of paper.",
    avatar:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=334&q=80',
    color: '#7bc043 ',
    icon: 'briefcase',
    call: 'Learn More',
    url: '/article/hiring'
  },
  {
    name: 'Personal Testing',
    role: 'Discover your IQ as well as your cognitive strengths and weaknesses with our online test',
    content:
      'Our vast collections of data and intelligent sampling ensures our online IQ test is the most accurate available. We take special care to avoid sampling and cultural biases. We offer a certified IQ score and a multiple intelligences report.',
    avatar:
      'https://images.unsplash.com/photo-1606513542745-97629752a13b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
    color: '#0392cf',
    icon: 'users',
    call: 'Take our Personal Test',
    url: '/starttest'
  },
];

const backgrounds = [
  `url("data:image/svg+xml, %3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'560\' height=\'185\' viewBox=\'0 0 560 185\' fill=\'none\'%3E%3Cellipse cx=\'102.633\' cy=\'61.0737\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23ED64A6\' /%3E%3Cellipse cx=\'399.573\' cy=\'123.926\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23F56565\' /%3E%3Cellipse cx=\'366.192\' cy=\'73.2292\' rx=\'193.808\' ry=\'73.2292\' fill=\'%2338B2AC\' /%3E%3Cellipse cx=\'222.705\' cy=\'110.585\' rx=\'193.808\' ry=\'73.2292\' fill=\'%23ED8936\' /%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ED8936'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%2348BB78'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%230BC5EA'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%23ED64A6'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23ED8936'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%2348BB78'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%230BC5EA'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23ED64A6'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ECC94B'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%239F7AEA'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%234299E1'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%2348BB78'/%3E%3C/svg%3E")`,
];

interface TestimonialCardProps {
  name: string;
  role: string;
  content: string;
  avatar: string;
  index: number;
}

function TestmonialCard(props: TestimonialCardProps) {
  const { name, role, content, color, icon, index, call, url } = props;
  return (
    <Flex
      boxShadow={'lg'}
      maxW={'640px'}
      direction={{ base: 'column-reverse', md: 'row' }}
      width={'full'}
      rounded={'xl'}
      p={10}
      justifyContent={'space-between'}
      position={'relative'}
      bg={useColorModeValue('white', 'gray.800')}
      _before={{
        content: '""',
        position: 'absolute',
        zIndex: '-1',
        height: 'full',
        maxW: '640px',
        width: 'full',
        filter: 'blur(40px)',
        transform: 'scale(0.98)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        top: 0,
        left: 0,
        backgroundImage: backgrounds[index % 4],
      }}>
      <Flex
        direction={'column'}
        textAlign={'left'}
        justifyContent={'space-between'}>
        <chakra.p fontWeight={'bold'} fontSize={30}>
          {name}
        </chakra.p>
        <chakra.p
          lineHeight={1}
          fontWeight={'medium'}
          color={'gray.500'} fontSize={20}
          lineHeight={1.3}
          mt={2}
          pb={4}>
          {role}
        </chakra.p>

        <chakra.p
          fontWeight={'medium'}
          fontSize={'15px'}>
          {content}
        </chakra.p>
        <Link to={url}>
          <Button mt={8} mb={3} width={IsMobile() ? "100%" : 200}>{call}</Button>
        </Link>
      </Flex>
      <Center m={{ base: '0 0 35px 0', md: '0 0 0 50px' }}>
        <FontAwesomeIcon
          color={color}
          icon={icon}
          size="4x"
        />
      </Center>
    </Flex>
  );
}

export function Card() {
  return (
    <Flex
      textAlign={'center'}
      pt={10}
      justifyContent={'center'}
      direction={'column'}
      width={'full'}>
      <SimpleGrid
        columns={{ base: 1, xl: 2 }}
        spacing={'20'}
        mt={16}
        mx={'auto'}>
        {testimonials.map((cardInfo, index) => (
          <TestmonialCard {...cardInfo} index={index} />
        ))}
      </SimpleGrid>
    </Flex>
  );
}
