import React from 'react';
import { Heading, Text, Box, Button } from '@chakra-ui/react';
import { Quiz } from './Quiz';
import { Link } from 'react-router-dom';

export function Header(props) {
  const setPage = props.setPage;

  return (
    <Box align="center" justify="center" pt={90}>
        <Heading size="xl">
          Welcome to Aptilink
        </Heading>
        <Text fontSize="xl">
          The world's premier Intelligence Quotient testing service
        </Text>
        <Link to="/starttest">
          <Button mt={5}>Take our Personal IQ Test now</Button>
        </Link>
    </Box>
  );
}
