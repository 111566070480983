import {React, useState, useEffect, useReducer} from 'react';
import {
  Box,
  Heading,
  Text,
  Center,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { IsMobile } from './IsMobile';
import { Link } from 'react-router-dom';

function LoadingBar(props) {
  const intelligenceType = props.intelligenceType;
  var percentageFull = props.percentageFull;
  const ind = props.ind;
  const loadingTurn = props.loadingTurn;
  var width;

  if(percentageFull < 0) { percentageFull = 0; }
  if(ind < loadingTurn) { width = "100%"; }
  if(ind == loadingTurn) { width = percentageFull+"%"; }
  if(ind > loadingTurn) { width = "0%"; }

  var backgroundColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box>
    {!IsMobile() &&
    <Center mt={5}>
      <Heading fontSize={15} fontWeight={500} letterSpacing=".01px" width="300px">
        {intelligenceType}
      </Heading>
      <Box width="100%" height="10px" className="questionBar" backgroundColor={backgroundColor} mb={1} mt={1}>
        <Box width={width} height="10px" backgroundColor="#008000" opacity="0.9">
        </Box>
      </Box>
      <CheckIcon color="#008000" width="50px" fontSize={20} className={parseInt(width) >= 100 ? "fadeIn" : "fadeIn hide"} />
    </Center>
    }
    {IsMobile() &&
      <Box pl={4} mt={1}>
      <Heading fontSize={15} fontWeight={500} letterSpacing=".01px" width="300px">
        {intelligenceType}
      </Heading>
      <Center>
        <Box width="100%" height="10px" className="questionBar" backgroundColor={backgroundColor} mb={1} mt={1}>
          <Box width={width} height="10px" backgroundColor="#008000" opacity="0.9">
          </Box>
        </Box>
        <CheckIcon color="#008000" width="50px" fontSize={20} className={parseInt(width) >= 100 ? "fadeIn" : "fadeIn hide"} />
      </Center>
      </Box>
    }
    </Box>
  );
}

function PercentageMeter(props) {
  const loadingTurn = props.loadingTurn;
  var counter = props.counter;
  if(counter > 100) { counter = 100; }
  var percentage = parseInt((25*loadingTurn)+((100-counter)/4));
  if(percentage > 100) {percentage = 100;}
  return (
    <Box mt={8} mb={3}>
      <Box className={"default c100 center p"+percentage+ " " + useColorModeValue('', 'dark')}>
        <div class="slice" style={{opacity:"0 !important"}}>
          <div class="bar"></div>
          <div class="fill"></div>
        </div>
        <span className="chakra-heading">
          {percentage+"%"}
        </span>
      </Box>
    </Box>
  );
}

var milSmall = 0;
var milLarge = 2;

export function CalculationPage() {
  const [counter, setCounter] = useState(100);
  const [loadingTurn, setLoadingTurn] = useState(0);
  const [, forceUpdate] = useState(1);



  useEffect(() => {
    counter > 0 && setTimeout(() => {
      var now = new Date();
      var milliseconds = now.getMilliseconds()
      var milHundreds = parseInt(milliseconds/100);
      if(milHundreds < milSmall) {
        setCounter(counter-1);
      } else {
        setCounter(counter-0.1)
        milSmall = Math.floor(Math.random() * 10);
        console.log(milSmall);
      }
    }, 10);
  }, [counter]);

  if(counter <= 0 && loadingTurn < 4) {
    setCounter(130);
    setLoadingTurn(loadingTurn+1);
    if(loadingTurn == 3) {
      setCounter(40);
    }
  }

  return (
    <Center pl={2} pr={2}>
      <Box>
        <Center>
          <Heading fontSize={20} fontWeight={600} align="center">
            Please wait a moment while we calculate your results
          </Heading>
        </Center>

        <Center mt={2}>
          <PercentageMeter loadingTurn={loadingTurn} counter={counter} />
        </Center>

        <Box pt={2}>
          <LoadingBar intelligenceType="Pattern Recognition" percentageFull={100-counter} loadingTurn={loadingTurn} ind={0} />
          <LoadingBar intelligenceType="Numerical Intelligence" percentageFull={100-counter} loadingTurn={loadingTurn} ind={1} />
          <LoadingBar intelligenceType="Spatial Intelligence" percentageFull={100-counter} loadingTurn={loadingTurn} ind={2} />
          <LoadingBar intelligenceType="Deductive Reasoning" percentageFull={100-counter} loadingTurn={loadingTurn} ind={3} />
        </Box>
        <Center mt={8}>
        <Link to="/payment">
        <Button className={loadingTurn == 4 && counter <= 0 ? "fadeIn" : "fadeIn hide"}>
          View Your Results
        </Button>
        </Link>
        </Center>
      </Box>
    </Center>
  )
}
