import React from 'react';
import {
  Box,
  Heading,
  Link,
  chakra,
  Center,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Button,
  Flex,
} from '@chakra-ui/react';

export function Defense() {
  return (
    <div>
    <chakra.p fontWeight={'bold'} fontSize={33}>
      Defence Force Aptitude Testing
    </chakra.p>
    <chakra.p
      lineHeight={1}
      fontWeight={'medium'}
      color={'gray.500'} fontSize={22}
      lineHeight={1.3}
      mt={2}
      pb={5}>
      Comprehensive aptitude and psychometric analysis for military and police use
    </chakra.p>

    <chakra.p
      lineHeight={1.4}
      letterSpacing={'.1px'}
      fontSize={'20px'}>
      There is a wide variety of positions and job profiles in defense forces each demanding a certain set of skills. Our aptitude tests empower recuriters to make informed decisions with regard to matching requisites to abilities and personalities.
      <br /><br />
      We assess diagrammatic reasoning, numerical reasoning, verbal reasoning, EQ, personanilty and motivation. With our results you can find the best fit for each candidate.
      <br /><br />
      Our tests are administered online and reports are generated within a day. Reach out to us below for a quote.
    </chakra.p>
    <a href="mailto:info@Aptilink.io">
    <Button mt={8} width={200}>Inquire</Button>
    </a>
    </div>
  )
}
