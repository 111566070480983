import React, { useState } from 'react';
import { Question } from './Question';
import { Navigation } from './Navigation';
import { Flex, Box } from '@chakra-ui/react';
import { QuizInfo } from './QuizInfo';
import { bake_cookie, read_cookie } from 'sfcookies';
import { IsMobile } from './IsMobile';
import { IsBigQuiz } from './IsBigQuiz';

if(!document.cookie.match(/^(.*;)?\s*answers\s*=\s*[^;]+(.*)?$/)) {
  let answers = {};
  for(let i=0; i<40; ++i) {
    answers[i] = '-';
  }
  bake_cookie('answers', JSON.stringify(answers));
}

export function Quiz(props) {
  const questionNum = props.questionNum;
  const setQuestionNum = props.setQuestionNum;

  return (
    <Flex align="center" justify="center" mt={IsBigQuiz() ? "25px" : "-60px"}>
      <Box>
        <QuizInfo questionNum={questionNum} />
        <Box mt={-5}>
        <Navigation questionNum={questionNum} setQuestionNum={setQuestionNum} />
        </Box>
        <Question questionNum={questionNum} setQuestionNum={setQuestionNum} />
      </Box>
    </Flex>
  );
}
