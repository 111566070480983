import React from 'react';
import { Heading } from '@chakra-ui/react';

export const Logo = props => {
  return (
    <Heading fontSize={25}>
      Aptilink
    </Heading>
  );
};
