import React from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import { Box, Center } from '@chakra-ui/react';

const lowerBound = 69, upperBound = 131;

const normalY = (x, mean, stdDev) => Math.exp((-0.5) * Math.pow((x - mean) / stdDev, 2));

const getMean = (lowerBound, upperBound) => (upperBound + lowerBound) / 2;

// distance between mean and each bound of a 95% confidence interval
// is 2 stdDeviation, so distance between the bounds is 4
const getStdDeviation = (lowerBound, upperBound) => (upperBound - lowerBound) / 4;



const generatePoints = (lowerBound, upperBound) => {
  let stdDev = getStdDeviation(lowerBound, upperBound);
  let min = lowerBound - 2 * stdDev;
  let max = upperBound + 2 * stdDev;
  let unit = 1;
  var values = [];
  for(var i=min; i<max; i+=unit) {
    values.push(i);
  }
  return values;
}

let mean = getMean(lowerBound, upperBound);
let stdDev = getStdDeviation(lowerBound, upperBound);
let points = generatePoints(lowerBound, upperBound);

function GetZPercent(z) {

  // z == number of standard deviations from the mean

  // if z is greater than 6.5 standard deviations from the mean the
  // number of significant digits will be outside of a reasonable range

  if (z < -6.5) {
    return 0.0;
  }

  if (z > 6.5) {
    return 1.0;
  }

  var factK = 1;
  var sum = 0;
  var term = 1;
  var k = 0;
  var loopStop = Math.exp(-23);

  while(Math.abs(term) > loopStop) {
    term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
    sum += term;
    k++;
    factK *= k;
  }

  sum += 0.5;

  return sum;
}




export function IQChart(props) {
  let seriesData = points.map(x => ({name: (100*GetZPercent((x-100)/15)).toFixed(2)+'th Percentile', x, y: normalY(x, mean, stdDev)}));
  const options = {
    chart: {
          backgroundColor: 'transparent',
          type: 'area',
          height: 300,
      },
      title: {
        text: (100*GetZPercent((props.IQ-100)/15)).toFixed(2)+'%',
        y: 200,
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
        title: ''
      },
      legend: {
        enabled: false,
      },
      series: [{
          data: seriesData,
          name: 'Percentile'
      }],
      tooltip: {
        headerFormat: '',
        pointFormat: '<b>{point.name}</b><br/>IQ : <b>{point.x}</b>',
      },
      plotOptions: {
        area: {
          color: 'rgb(226, 119, 122)',
          fillColor: 'rgba(226, 119, 122, 0.5)',
          zoneAxis: 'x',
          zones: [{
            //fillColor gets the inside of the graph, color would change the lines
            fillColor: 'white',
            // everything below this value has this style applied to it
            value: 0,
          },{
            value: props.IQ,
          },{
            fillColor: 'white',
          }]
        },
      },
  };

  return (
    <Center mt="-50px">
      <div style={{ width: 360 }}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </Center>
  );
}
