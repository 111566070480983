import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { useColorModeValue } from '@chakra-ui/react';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
const stripePromise = loadStripe("pk_live_cQ7vjhfURDxqdTE14AzaZclA00WMu6u4aD");

export function Checkout(props) {
  const [clientSecret, setClientSecret] = useState("");
  const assessment = props.assessment;

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    let data = {'assessment': assessment};
    fetch("https://tws-audio.com/iq/create.php", {
      method: "POST",
      body:JSON.stringify(data)
    }).then(res => res.text())
    .then(data => {
      console.log(data);
      setClientSecret(data)
    })
  }, []);

  const appearance = {
    theme: useColorModeValue('stripe', 'night')
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="stripePayment">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm assessment={assessment} />
        </Elements>
      )}
    </div>
  );
}
