import React, { useState } from 'react';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  useColorModeValue,
  Button,
  Center,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage
} from '@chakra-ui/react';
import { read_cookie } from 'sfcookies';
import { IQChart } from './IQChart';
import { getResults } from './GetResults';

var answers;

function MultipleIntelligences(responseJSON) {
  var reports = [];
  for (var k in responseJSON){
    if(k != 'IQ') {
      reports.push(Stats(responseJSON[k], k));
    }
  }

  return (
    <Box>
      {reports}
    </Box>
  )
}

function GetZPercent(z) {

  // z == number of standard deviations from the mean

  // if z is greater than 6.5 standard deviations from the mean the
  // number of significant digits will be outside of a reasonable range

  if (z < -6.5) {
    return 0.0;
  }

  if (z > 6.5) {
    return 1.0;
  }

  var factK = 1;
  var sum = 0;
  var term = 1;
  var k = 0;
  var loopStop = Math.exp(-23);

  while(Math.abs(term) > loopStop) {
    term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
    sum += term;
    k++;
    factK *= k;
  }

  sum += 0.5;

  return sum;
}

function Stats(IQ, statName) {
  return (
    <Box mb={10}>
      <Center mt={5}>
      <Heading align="center" fontSize="30px">
        Your {statName}{statName != 'IQ' && " IQ"} is {IQ}
      </Heading>
      </Center>
      <IQChart IQ={IQ} />
      <Center fontSize="25px" mt={5}>
      <p align="center">
        Your {statName} is in the top {(100-((100*GetZPercent((IQ-100)/15)))).toFixed(2)+'%'}
      </p>
      </Center>
      <Center>
      <Center maxWidth="600px" fontSize="25px" mt={1}>
      <p align="center">
        In a room of 1000 people you would be smarter than {(((100*GetZPercent((IQ-100)/15)))).toFixed(1)*10} of them.
      </p>
      </Center>
      </Center>
    </Box>
  )
}

export function Results(props) {
  const [response, setResponse] = useState('loading');
  var responseJSON = {};
  var IQ;
  if(response == 'loading') {
    getResults(setResponse);
  } else {
    responseJSON = JSON.parse(response);
    IQ = responseJSON['IQ'];
  }

  const assessment = props.assessment;

  var name = 'James Smith'
  return (
    <Box>
      {response != 'loading' && (
      <Box>
      <Center mt={2}>
      <Heading>Results</Heading>
      </Center>
      {Stats(IQ, "IQ")}
      {props.assessment == 'full' && (
        MultipleIntelligences(responseJSON)
      )}
      {(props.assessment == 'full' || props.assessment == 'certificate') && (
        <form onSubmit={() => window.open('https://tws-audio.com/iq/new%20cert/cert.html?data='+btoa(document.getElementById('nameInput').value)+'&token='+btoa(IQ), '_blank').focus()}>
          <Center>
            <FormControl isRequired={true} maxWidth="400px">
            <Input placeholder="Your Name..." id="nameInput" />
            </FormControl>
          </Center>
          <Center>
          <Button mt={5} type="submit">Download your Certificate</Button>
          </Center>
        </form>
      )}
      </Box>
    )}
    {response == 'loading' && (
      <Box>
      <Center mt={2}>
      <Heading>Validating Payment...</Heading>
      </Center>
      </Box>
    )}
    </Box>
  );
}
